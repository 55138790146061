@import '../../../styles/customMediaQueries.css';

.categoryContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.categoryCard {
  width: 330px;
  height: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--wheelplaceSteel);
  margin: 0 0 16px 0;
}

.categoryCard img {
  height: 50%;
}

.categoryButton {
  font-size: 18px !important;
  border-radius: 10px 10px 10px 10px !important;
  margin: 0 0 0 0;
  width: 100px;
}

@media (--viewportMedium) {
  .categoryContainer {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .categoryCard {
    width: 30%;
  }

  /* .category-card:nth-child(3) {
    width: 100%;
    max-width: calc(100% - 2rem);
    order: 3;
  } */
}

@media (--viewportLarge) {
  .categoryContainer {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 0 0;
  }

  .categoryCard {
    width: 400px;
    height: 400px;
  }

  .categoryCard img {
    width: 200px;
    height: 200px;
    margin: 0 0 40px 0;
  }

  /* .category-card:nth-child(3) {
    width: 400px;
    max-width: none;
    order: 0;
  } */

  .categoryButton {
    width: 130px;
    font-size: 28px !important;
  }
}
